<script>
    import ModalCloseButton from "$lib/ModalCloseButton.svelte";
    import {quadOut} from "svelte/easing";

    export let showClose = true;
    export let noPadding = false;
    export let pinToTop = false;
    export let verticalCenter = false;
    export let extendDown = false;
    export let w100 = false;
    export let onClose = () => {
        hide();
    };

    let isOpen = false;

    export function show() {
        isOpen = true;
    }

    export function hide() {
        isOpen = false;
    }

    let onClickBackground = () => {
        if (showClose) onClose();
    }

    let overlayAnim = _ => ({
        duration: 300,
        easing: quadOut,
        css: (t, _) => `
            background: rgba(26, 29, 47, ${t * 0.7});
            backdrop-filter: blur(${t * 3}px);
        `
    });


    let modalAnimShow = _ => ({
        duration: 300,
        easing: quadOut,
        css: (t, _) => `
            opacity: ${t};
            transform: translateY(${(1 - t) * 100}px);
        `
    });

</script>

{#if isOpen}
    <div class="overlay"
         on:click={onClickBackground}
         on:scroll|stopPropagation={_=>true}
         on:wheel|stopPropagation={_=>true}
         on:touchmove|stopPropagation={_=>true}
         class:isOpen class:verticalCenter in:overlayAnim out:overlayAnim>
        <div class="modal" class:noPadding class:pinToTop class:verticalCenter class:extendDown class:w100
             on:click|stopPropagation
             in:modalAnimShow
             out:modalAnimShow {...$$restProps}>
            {#if showClose}
                <ModalCloseButton on:click={_=>onClose()}/>
            {/if}
            <slot></slot>
        </div>
    </div>
{/if}

<style lang="scss">
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;

    &.verticalCenter {
      align-items: center;
    }

    background: rgba(26, 29, 47, 0.70);
    backdrop-filter: blur(3px);

    .modal {
      box-sizing: border-box;
      position: relative;
      margin: 15px;
      margin-top: 121px;

      &.w100 {
        width: 100%;
      }

      &.extendDown {
        height: 100%;
      }

      &.pinToTop {
        margin-top: 15px;
      }

      &.verticalCenter {
        margin-top: 0;
      }

      padding: 40px 29px;

      &.noPadding {
        padding: 0 !important;
      }

      background-color: var(--color-background-dark);
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      gap: 16px;
    }

  }
</style>